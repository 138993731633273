export default {
    init() {
        console.log('common');
    },
    finalize() {

        function animate_all(){

            const s1 = new TimelineMax();
            s1
                .set(['.anime-0','.anime-1','.anime-2','.anime-3','.anime-4','.anime-5','.anime-6','.anime-7','.anime-8','.anime-9'], {
                    autoAlpha: 0,
                })
                .to(['.anime-0'], 1, {
                    autoAlpha: 1,
                }, .25)
                .to(['.anime-1'], 1, {
                    autoAlpha: 1,
                }, .5)
                .to(['.anime-2'], 1, {
                    autoAlpha: 1,
                }, .75)
                .to(['.anime-3'], 1, {
                    autoAlpha: 1,
                }, 1)
                .to(['.anime-4'], 1, {
                    autoAlpha: 1,
                }, 1.25)
                .to(['.anime-5'], 1, {
                    autoAlpha: 1,
                }, 1.5)
                .to(['.anime-6'], 1, {
                    autoAlpha: 1,
                }, 1.75)
                .to(['.anime-7'], 1, {
                    autoAlpha: 1,
                }, 7)
                .to(['.anime-8'], 1, {
                    autoAlpha: 1,
                }, 8)
                .to(['.anime-9'], 1, {
                    autoAlpha: 1,
                }, 9)

            const s2 = new TimelineMax();
            var staggerSet = ['.anime-stgr-0','.anime-stgr-1','.anime-stgr-2','.anime-stgr-3','.anime-stgr-4','.anime-stgr-5','.anime-stgr-6','.anime-stgr-7','.anime-stgr-8','.anime-stgr-9']
            s2
                .set(staggerSet, {
                    autoAlpha: 0,
                })
                .delay(2)
                .staggerTo(staggerSet, .5, {
                    autoAlpha: 1,
                }, .25)
        }

        animate_all();

        (function($) {

            $('.cpl-arrow-btn').hover(function(){
                const s1 = new TimelineMax();
                s1
                    .to($('span',this), .25, {
                        x: 5,
                    }, 0)

            },function(){
                const s1 = new TimelineMax();
                s1
                    .to($('span',this), .1, {
                        x: 0,
                    }, 0)
            });

            let navState = false;

            let slinky = null;  // Initialize the var

            $("#mobile-nav-btn").on("click", function(){
                if(navState === false){
                    $(this).html('<i class="fal fa-times-circle"></i>');
                    menuOpen();
                    navState = true;

                    // Create it when the nav wrapper element is made so correctly initializes.
                }
                else {
                    $(this).html('<i class="fal fa-bars"></i>');
                    menuClose();
                    navState = false;
                    window.setTimeout( ()=>{slinky.destroy()}, 500 ); // Wait a little so we don't flash the destroy.
                }
            });

            function menuOpen() {

                $('#nav-wrapper').show();

                const s1 = new TimelineMax();
                s1
                    .to(['#nav-wrapper'], .8, {
                        autoAlpha: 1,
                        ease: Back.easeOut.config(1.4)
                    }, 0)

                const s2 = new TimelineMax();
                // var staggerSet = '#menu-mobile-menu li';
                // s2
                //     .set(staggerSet, {
                //         autoAlpha: 0,
                //     })
                //     .staggerTo(staggerSet, 1, {
                //         autoAlpha: 1,
                //     }, .1)
            }

            function menuClose() {

                function goHide() {
                    $('#nav-wrapper').hide();
                };

                const s1 = new TimelineMax({
                    onComplete: goHide
                });
                s1
                    .to(['#nav-wrapper'], .8, {
                        autoAlpha: 0,
                        ease: Back.easeOut.config(1.4)
                    }, 0)

            }

        })( jQuery );

    },
};
