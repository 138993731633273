import 'bootstrap';
import jQuery from 'jquery';
import './style.scss';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';

import WebFont from 'webfontloader';

import { TweenMax, TimelineLite, Power2, Elastic, CSSPlugin } from 'gsap/TweenMax'

import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faPrint } from '@fortawesome/pro-solid-svg-icons'
import { faCircle, faLongArrowRight, faPlus, faBars, faPhone, faTimesCircle, faEnvelope } from '@fortawesome/pro-light-svg-icons'
import { faFacebookSquare, faYoutube, faFacebookF, faTwitter, faLinkedinIn, faTwitterSquare, faInstagram } from '@fortawesome/free-brands-svg-icons'


// Add all icons to the library so you can use it in your page
library.add( faCircle, faLongArrowRight, faPlus, faBars, faPhone, faTimesCircle, faFacebookSquare, faYoutube, faFacebookF, faTwitter, faLinkedinIn, faPrint, faInstagram, faEnvelope );

dom.i2svg();

dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
});

// Initialize webfonts
WebFont.load({
    google: {
        families: ['Montserrat:300,500']
    },
    custom: {
        families: ['Spy Agency Bold'],
        urls: ['/wp-content/themes/Capabl/fonts/custom-fonts.css']
    }
});

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home
    /** About Us page, note the change from about-us to aboutUs. */
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());